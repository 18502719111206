import * as React from "react";
import { Grid } from "react-flexbox-grid";
import Helmet from "react-helmet";

const AccessDeniedPage = () => {
  // custom styles
  const containerStyle = {
    maxWidth: "800px",
    margin: "0 auto 4rem",
  };

  return (
    <>
      <Helmet>
        <title>Access Denied | Case Western Reserve University</title>
      </Helmet>
      <Grid>
        <div style={containerStyle}>
          <h1>Access Denied</h1>
          <p>
            It appears you do not have appropriate access to this page. If you
            believe this in error, please contact{" "}
            <a href="mailto:webteam@case.edu">webteam@case.edu</a>.
          </p>
        </div>
      </Grid>
    </>
  );
};

export default AccessDeniedPage;
